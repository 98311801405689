import { copyFooter } from "@/constants/footer";
import Image from "next/image";
import Link from "next/link";

import Logo from "@/public/assets/logo/pina.png";
import LogoTrust from "@/public/assets/logo/trust.png";

export default function Footer() {
  const locale = "id";
  const year = new Date().getFullYear();

  return (
    <footer className="bg-[#F0F2F3] pt-5">
      <div className="container pt-5 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto pb-10 px-6 md:px-4">
          <div className="grid grid-cols-5">
              <div className="col-span-3">
                <div>
                   
                    <div className="mb-4">
                    <a href={"https://pinatrade.id"}>
                      <Image
                        src={Logo}
                        alt="Pina Indonesia"
                        layout="fixed"
                        objectFit="contain"
                        width={200}
                        // height={'auto'}
                        
                      />
                       </a>
                    </div>
                 
                  {/* <p className="text-xs mb-2 uppercase">Powered By</p> */}
                  
                <div>
                <a href="https://www.trust.co.id/">
                  <Image
                    src={LogoTrust}
                    alt="PT Trust Sekuritas"
                    layout="fixed"
                    lazyBoundary="-80px"
                    width={115}
                    height={37}
                  />
                  </a>
                </div>
              

                <div className="text-xs text-black mt-4">
                  <p className="font-bold text-sm">PT Trust Sekuritas</p>
                  <p className="text-[10px] leading-[14px] md:text-[12px] text-[#78776F]">Prosperity Tower, District 8, SCBD <br/>Jln. Jend. Sudirman kav 52-53 no lot 28 <br/>Senayan, Kebayoran Baru, Jakarta 12190</p>
                </div>
                </div>
              </div>
              <div className="flex flex-col col-span-2 place-content-between ml-6">
                <div className="flex gap-4 justify-end">
                <div className="self-center"> 
                <Link href="/blog">
                   <a   className="text-olive-600 p-2 text-sm hover:text-olive-600/60 self-center">Artikel</a>
                   </Link>  
                   </div>
                <div className="self-center"> 
                <Link href="/privacy"> 
                  <a  className="text-olive-600 p-2 text-sm hover:text-olive-600/60 mr-8 self-center">Kebijakan Privasi</a>
                  </Link> 
                  </div>
                  <div>
                    <Link href="https://www.instagram.com/pinatrade.id">
                      <a target="_blank" className="bg-olive-600 p-2 block rounded-full hover:bg-olive-600/60">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3 8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8ZM8 5C6.34315 5 5 6.34315 5 8V16C5 17.6569 6.34315 19 8 19H16C17.6569 19 19 17.6569 19 16V8C19 6.34315 17.6569 5 16 5H8Z"
                            fill="#fff"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.5 8.5C17.0523 8.5 17.5 8.05228 17.5 7.5C17.5 6.94772 17.0523 6.5 16.5 6.5C15.9477 6.5 15.5 6.94772 15.5 7.5C15.5 8.05228 15.9477 8.5 16.5 8.5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                    </Link>
                  </div>
                
                  <div>
                    <Link href="https://www.youtube.com/@PINATrade">
                      <a target="_blank" className="bg-olive-600 p-2 block rounded-full hover:bg-olive-600/60">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 6C9.45073 6 7.31021 6.0974 5.72494 6.20483C4.89327 6.2612 4.22483 6.91721 4.16689 7.80827C4.07733 9.18566 4 10.7453 4 12C4 13.2547 4.07733 14.8143 4.16689 16.1917C4.22483 17.0828 4.89327 17.7388 5.72494 17.7952C7.31021 17.9026 9.45073 18 12 18C14.5493 18 16.6898 17.9026 18.2751 17.7952C19.1067 17.7388 19.7752 17.0828 19.8331 16.1917C19.9227 14.8143 20 13.2547 20 12C20 10.7453 19.9227 9.18566 19.8331 7.80827C19.7752 6.91721 19.1067 6.2612 18.2751 6.20483C16.6898 6.0974 14.5493 6 12 6ZM5.58971 4.20941C3.72849 4.33555 2.29215 5.81694 2.1711 7.6785C2.08072 9.06851 2 10.6796 2 12C2 13.3204 2.08072 14.9315 2.1711 16.3215C2.29215 18.1831 3.72849 19.6645 5.58971 19.7906C7.21433 19.9007 9.40027 20 12 20C14.5997 20 16.7857 19.9007 18.4103 19.7906C20.2715 19.6645 21.7078 18.1831 21.8289 16.3215C21.9193 14.9315 22 13.3204 22 12C22 10.6796 21.9193 9.06851 21.8289 7.6785C21.7078 5.81694 20.2715 4.33555 18.4103 4.20941C16.7857 4.09931 14.5997 4 12 4C9.40027 4 7.21433 4.09931 5.58971 4.20941Z"
                            fill="#fff"
                          />
                          <path
                            d="M10 10.0016C10 9.20428 10.8873 8.72761 11.5521 9.16775L14.5455 11.1497C15.1547 11.5531 15.1546 12.4474 14.5454 12.8507L11.552 14.8325C10.8873 15.2726 10 14.7959 10 13.9986V10.0016Z"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="hidden md:flex flex md:gap-4 justify-end items-center">
                
                  <span className="relative w-[120px] h-[60px]">
                  <Image
                    src="/assets/logo/idx-gray.png?1"
                    layout={'fill'} objectFit={'contain'}
                  />
                  </span>
                  <span className="relative w-[120px] h-[60px]">
                  <Image
                    src="/assets/logo/ksei-gray.png"
                    layout={'fill'} objectFit={'contain'}
                  />
                  </span>
                  <span className="relative w-[120px] h-[60px]">
                    <p className="text-xs">Terdaftar dan diawasi oleh</p>
                  <span className=" text-3xl font-semibold">
                  OJK
                  </span>
                  </span>

                  <span className="relative w-[120px] h-[60px]">
                  <Image
                    src="/assets/logo/kpei.png?1"
                    layout={'fill'} objectFit={'contain'}
                  />
                  </span>
                  
                  
                </div>
              </div>
              
          </div>
          <div className="flex md:hidden justify-between w-full items-center mt-6 gap-4">
                
                  <span className="relative w-[120px] h-[60px]">
                  <Image
                    src="/assets/logo/idx-gray.png?1"
                    layout={'fill'} objectFit={'contain'}
                  />
                  </span>
                  <span className="relative w-[120px] h-[60px]">
                  <Image
                    src="/assets/logo/ksei-gray.png"
                    layout={'fill'} objectFit={'contain'}
                  />
                  </span>
                  <span className="relative w-[120px] h-[60px]">
                    <p className="text-xs">Terdaftar dan diawasi oleh</p>
                  <span className=" text-3xl font-semibold">
                  OJK
                  </span> </span>

                  <span className="relative w-[120px] h-[60px]">
                  <Image
                    src="/assets/logo/kpei.png?1"
                    layout={'fill'} objectFit={'contain'}
                  />
                  </span>
                  
                  
                </div>
      </div>
  
    </footer>
  );
}
